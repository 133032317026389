.container {
    font-family: 'FuturaPT-Demi', Arial, sans-serif;
    font-size: 12px;
    text-transform: uppercase;
    text-align: center;
    margin: 30px 0 40px;

    .items {
        box-shadow: inset 0px 0px 6px rgba(0, 0, 0, 0.1);
        border-radius: 24px;
        display: inline-block;

        a {
            color: #BDC0C7;
            padding: 14px 15px;
            position: relative;
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

            .basket {
                color: #fff;
                background: #f55555;
                width: 20px;
                height: 20px;
                line-height: 20px;
                border-radius: 100%;
                position: absolute;
                top: -7px;
                right: -5px;
                box-shadow: 0 0 5px #fff;
            }
        }

        .active {
            color: #fff;
            background: linear-gradient(93.75deg, #F98C45 0%, #F55555 100%);
            border-radius: 21px;
            box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
        }
    }
}