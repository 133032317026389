.photoblock{box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05), 0px 10px 70px #E2E8F6;border-radius: 10px;padding: 16px 16px 10px;
    margin-bottom: 20px;}
.photoblock__title{font-size: 18px;margin-bottom: 20px;}
.photoblock img{border-radius: 2px;}
.photoblock__images > div{display: inline-block;max-width: 70px;margin: 0 7px 7px 0;}
.dynamics__block{padding: 10px 5px 10px 107px;position: relative;box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05), 0px 10px 70px #E2E8F6;
    border-radius: 10px;margin-bottom: 20px;min-height: 98px;max-width: 330px;}
.dynamics__img{position: absolute;top: 10px;left: 10px;}
.dynamics__title{font-size: 18px;margin: 8px 0;}
.dynamics__block .btn{font-size: 10px;padding: 9px 11px;margin-right: 8px;vertical-align: top;height: 30px;}
.dynamics__block .btn + .btn{padding-top: 8px;}
.dynamics__block .btn img{margin-top: -1px;}
.btn--disabled{opacity: 0.45;pointer-events: none;}
.photoadd__p{margin-bottom: 10px;}
.photoadd__row{margin:40px -5px 0;overflow: hidden;}
.photoadd__col{width:50%;padding: 0 5px 10px;float: left;}
.photoadd__block{border: 1px dashed #BDC0C7;border-radius: 10px;overflow: hidden;min-height: 150px;display: flex;align-items: center;
justify-content: center;flex-wrap: wrap;}
.photoadd__title{font-size: 12px;background: rgba(240,242,247,0.8);padding: 5px 0 8px;position: relative;width: 100%;}
.photoadd__title label{position:absolute;top:2px;right:2px;}
.photoadd__delete{position: absolute;left: 5px;top: 50%;margin-top: -8px;}
.photoadd__file{display: none;}
.photoadd__body{width:100%;height: 122px;display: flex;align-items: center;justify-content: center;}
.photoadd__body .btn{font-size: 10px;padding: 9px 11px;}
.photoadd__body svg{vertical-align: middle;}
.photoadd__body img{border-radius: 2px;}
@media (max-width: 767px){
    .dynamics__block{max-width: 100%;}
}