.wrapper {
  display: flex;
  min-height: 100vh;
  align-items: center;
  background-size: contain;
  margin: 0 16px;
}

.dialog {
  max-width: 767px;
  margin: 0 auto;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05),
    0px 10px 70px rgba(226, 232, 246, 0.8);
  border-radius: 40px;
  padding: 36px;
  position: relative;
  width: 100%;
  text-align: center;
}

.title {
  margin: 16px;

  @media (max-width: 768px) {
    font-size: 36px;
  }
}

.description {
  font-size: 26px;

  @media (max-width: 768px) {
    font-size: 20px;
  }
}
