@charset "UTF-8";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
input:focus,
select:focus,
button:focus,
textarea:focus,
a {
  outline: none;
}
input,
textarea,
select,
optgroup {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
a:hover,
a:focus {
  text-decoration: none;
}
*,
::after,
::before {
  box-sizing: border-box;
}
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1170px;
  }
}
@font-face {
  font-family: 'FuturaPT-Book';
  src: url('font/FuturaPT-Book.eot');
  src: url('font/FuturaPT-Book.eot?#iefix') format('embedded-opentype'),
    url('font/FuturaPT-Book.woff2') format('woff2'),
    url('font/FuturaPT-Book.woff') format('woff'),
    url('font/FuturaPT-Book.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'FuturaPT-Demi';
  src: url('font/FuturaPT-Demi.eot');
  src: url('font/FuturaPT-Demi.eot?#iefix') format('embedded-opentype'),
    url('font/FuturaPT-Demi.woff2') format('woff2'),
    url('font/FuturaPT-Demi.woff') format('woff'),
    url('font/FuturaPT-Demi.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'FuturaPT-Medium';
  src: url('font/FuturaPT-Medium.eot');
  src: url('font/FuturaPT-Medium.eot?#iefix') format('embedded-opentype'),
    url('font/FuturaPT-Medium.woff2') format('woff2'),
    url('font/FuturaPT-Medium.woff') format('woff'),
    url('font/FuturaPT-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'FuturaPT-Bold';
  src: url('font/FuturaPT-Bold.eot');
  src: url('font/FuturaPT-Bold.eot?#iefix') format('embedded-opentype'),
    url('font/FuturaPT-Bold.woff2') format('woff2'),
    url('font/FuturaPT-Bold.woff') format('woff'),
    url('font/FuturaPT-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
body {
  font-size: 16px;
  line-height: 1.25;
  color: #000;
  font-family: 'FuturaPT-Book', Arial, sans-serif;
  padding: 65px 0 100px;
  position: relative;
}
h1 {
  font-size: 44px;
  line-height: 1;
  margin: 40px 0;
}
h1,
.title,
.category__title,
.readmore__title,
.title__find,
.popup--style .MuiTypography-h6,
.MuiPaper-root .MuiTypography-h6,
select.portion,
b {
  font-family: 'FuturaPT-Bold', Arial, sans-serif;
}
.recipe__desc,
.recipe__links,
.note,
.recipe__top,
.product__sostav,
.product__counter,
.product__quantity,
.not__video,
.plan .label,
.not__rating,
.not__clock,
.instagram,
.formquestion p,
.board__place,
.board__block table div,
.info,
.profil__params p,
.measure__date {
  font-family: 'FuturaPT-Medium', Arial, sans-serif;
}
.recipe__title,
.tab__item,
.recipe__char,
.sostav__product,
.white_btn,
.steps__number,
.scrollul,
.product__name,
.download,
.not,
.plan__name,
.bottommenu,
.mobilemenu,
.telegram,
.viber,
.btn,
.chat__title,
.day,
.train__title,
.train__block .label,
.train__download,
.blocktitle,
button[type='submit'],
.board__block table p,
.profil__params div,
.planmenu,
.categorieslist,
.sostav table td:nth-child(2),
.MuiTab-wrapper,
.notfound__title,
.measure__numbers,
.popup--style .popup__bottom .MuiTypography-body1,
.label__time,
.photoblock__title,
.dynamics__title,
.subscription,
.photoadd__title {
  font-family: 'FuturaPT-Demi', Arial, sans-serif;
}
.measure__editpopup,
.formquestion .MuiTypography-body1,
.popup--style .MuiTypography-body1 {
  font-family: 'FuturaPT-Book', Arial, sans-serif;
}
h1 span,
.recipe__desc,
.recipe__links a,
.recipe__links span,
.recipe__top,
.white_btn,
.white_btn:hover,
.white_btn:focus,
.steps__number,
.product__link,
.plan__link,
.mobilemenu ul li a.active,
p a,
.board .board__col:first-child .board__block table p,
.title span,
.openblock,
.blocktitle span,
.categorieslist .active {
  color: #f55555;
}
a,
span {
  display: inline-block;
}
.title {
  font-size: 20px;
  line-height: 1.1;
  margin-bottom: 20px;
}
.slider {
  margin: 0 -15px 0 -8px;
}
.recipe__item {
  padding: 0 8px 40px;
  position: relative;
}
.recipe__image {
  display: block;
  height: 196px;
  border-radius: 10px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  position: relative;
}
.recipe__image.trains__image {
  background-position: top center;
}
.recipe__text {
  margin: -70px 15px 0;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05), 0px 10px 50px #e2e8f6;
  padding: 15px;
  border-radius: 10px;
  position: relative;
}
.recipe__desc,
.recipe__links {
  font-size: 10px;
  text-transform: uppercase;
}
.recipe__desc {
  margin-bottom: 7px;
}
.recipe__desc,
.recipe__title,
.recipe__links,
.recipe__text {
  display: block;
}
.label {
  color: #fff;
  line-height: 1;
  background: #f55555;
  padding: 3px 5px 2px;
  border-radius: 2px;
  margin-right: 3px;
}
.recipe__title {
  font-size: 18px;
  line-height: 20px;
  margin-bottom: 10px;
}
.recipe__links {
  max-height: 60px;
  overflow: auto;
}
.recipe__links a,
.recipe__links span {
  background: #ffe6e2;
  border-radius: 10px;
  padding: 3px 7px;
  margin: 0 6px 2px 0;
}
.recipe__links a:last-child,
.recipe__links span:last-child {
  margin-right: 0;
}
.recipe__links a.orange,
.search__ingredient:nth-child(3n + 3),
.recipe__links span.orange {
  color: #ff9f18;
  background: #fff0e2;
}
.recipe__links a.green,
.search__ingredient:nth-child(3n + 2),
.recipe__links span.green {
  color: #89a48c;
  background: #f0f6f5;
}
.wish,
.tobasket {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  position: absolute;
  top: 15px;
  z-index: 1;
}
.wish {
  background: url(images/wish.svg) no-repeat 50% 50% rgba(0, 0, 0, 0.1);
  right: 20px;
}
.tobasket {
  background: url(images/basket.svg) no-repeat 50% 50% rgba(0, 0, 0, 0.1);
  right: 55px;
}
.wish.active {
  background: url(images/wish_active.svg) no-repeat 50% 50% #fff;
}
.tobasket.active {
  background: url(images/basket_active.svg) no-repeat 50% 50% #fff;
}
.trainslider__a {
  position: relative;
}
.note,
.not {
  color: #fff;
  background: linear-gradient(93.75deg, #f98c45 0%, #f55555 100%);
  position: relative;
}
.note {
  padding: 15px 120px 15px 15px;
  margin: 40px 0;
  border-radius: 18px;
}
.not {
  font-size: 18px;
  line-height: 20px;
  display: block;
  padding: 30px 155px 30px 15px;
  margin: 10px 0 40px;
  border-radius: 10px;
}
.note__img {
  position: absolute;
  right: -10px;
  top: 50%;
  transform: translateY(-50%);
}
.note p {
  margin-bottom: 8px;
}
.alert-close {
  position: absolute !important;
  right: 10px;
  top: 9px;
}
form {
  display: block;
  position: relative;
  margin-bottom: 40px;
}
input[type='text'],
input[type='email'],
input[type='number'],
textarea,
select {
  height: 46px;
  background: rgba(230, 233, 241, 0.6);
}
input[type='text'],
input[type='email'],
input[type='number'],
textarea,
select,
input[type='password'],
.MuiInput-formControl {
  line-height: 1;
  border-radius: 10px;
  width: 100%;
  box-sizing: border-box;
  padding: 0 30px 0 15px;
}
::-webkit-input-placeholder {
  font-size: 16px;
  color: #a2a5ac;
  font-family: 'FuturaPT-Book', Arial, sans-serif;
}
:-moz-placeholder {
  font-size: 16px;
  color: #a2a5ac;
  font-family: 'FuturaPT-Book', Arial, sans-serif;
}
::-moz-placeholder {
  font-size: 16px;
  color: #a2a5ac;
  font-family: 'FuturaPT-Book', Arial, sans-serif;
}
:-ms-input-placeholder {
  font-size: 16px;
  color: #a2a5ac;
  font-family: 'FuturaPT-Book', Arial, sans-serif;
}
input[type='submit'] {
  width: 22px;
  height: 22px;
  background: url(images/zoom.svg) no-repeat 0 0;
  position: absolute;
  top: 11px;
  right: 17px;
}
input[type='text'],
input[type='email'],
input[type='submit'],
textarea,
button[type='submit'],
input[type='password'] {
  border: none;
}
.tab {
  margin-bottom: 35px;
}
.MuiTabs-root + .tab,
.tab.tab--search {
  margin-bottom: 0;
}
.tab__item,
.scrollul li {
  font-size: 12px;
  text-transform: uppercase;
  text-align: center;
  max-width: 70px;
}
.tab__item img,
.scrollul img,
.MuiAvatar-root.MuiAvatar-circle img {
  max-height: 46px;
}
.MuiAvatar-root.MuiAvatar-circle img {
  height: auto;
  width: auto;
}
.active .tab__img,
.Mui-selected .MuiAvatar-root.MuiAvatar-circle {
  background: linear-gradient(92.12deg, #f8764c 3.07%, #f55555 100%);
}
.MuiTabs-indicator {
  display: none;
}
.MuiTabs-root {
  margin-bottom: 5px;
}
.MuiTab-wrapper {
  font-size: 12px;
}
.MuiTab-labelIcon.MuiTab-root {
  min-width: 0;
  min-height: 0;
  padding: 0;
  opacity: 1;
}
.MuiTab-labelIcon.MuiTab-root:not(:last-child) {
  margin-right: 10px;
}
.MuiAvatar-root.MuiAvatar-circle {
  width: 70px;
}
.PrivateTabIndicator-root-1,
.PrivateTabIndicator-root-5 {
  display: none;
}
.tab__img,
.scrollul span,
.MuiAvatar-root.MuiAvatar-circle {
  height: 70px;
  line-height: 70px;
  background: #f0f2f7;
  border-radius: 10px;
  margin: 0 0 5px;
}
.tab__block {
  height: 0;
  overflow: hidden;
  margin-right: -15px;
  box-sizing: border-box;
}
.tab__block.active {
  height: auto;
}
.item,
.item:focus {
  outline: none;
}
#categories {
  margin: 0 -8px 40px -8px;
}
#categories .item,
#sostav .item {
  padding: 0 8px;
}
a {
  text-decoration: none;
}
a,
a:hover,
a:focus {
  color: #000;
}
img {
  display: inline-block;
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}
.recipe_bg {
  padding-top: 75px;
}
.recipe_topblock {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 335px;
  border-radius: 0 0 10px 10px;
}
.recipe_bg .container {
  position: relative;
}
.back {
  position: fixed;
  top: 70px;
  left: 15px;
  z-index: 1;
}
.back--disabled {
  opacity: 0.5;
  pointer-events: none;
}
.recipe {
  margin: 190px 0 30px;
  position: relative;
  padding: 15px 15px 10px;
}
.recipe,
.plan__norm {
  background: #fff;
  text-align: center;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05), 0px 10px 70px #e2e8f6;
  border-radius: 10px;
}
.plan__norm {
  padding: 20px 20px 10px;
  margin-bottom: 40px;
}
.plan__norm .title {
  padding-bottom: 15px;
  border-bottom: 1px solid #d7dbe7;
  margin-bottom: 10px;
}
.plan__norm .title span {
  display: inline;
}
.recipe__top {
  font-size: 12px;
  text-transform: uppercase;
  margin-bottom: 5px;
}
.recipe .title {
  max-width: 265px;
  margin: 0 auto 5px;
}
.recipe__parts {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.4);
  border-bottom: 1px solid #d7dbe7;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.recipe table,
.plan__norm table {
  font-size: 14px;
  width: 100%;
  text-align: center;
}
.recipe table tr td {
  width: 25%;
  padding: 2px 0;
}
.plan__norm table tr td {
  width: 33.33%;
  padding: 2px 0;
}
.recipe table tr td:not(:last-child),
.plan__norm table tr td:not(:last-child) {
  border-right: 1px solid #d7dbe7;
}
.recipe__char {
  font-size: 12px;
  text-transform: uppercase;
}
.recipe .wish,
.recipe .tobasket {
  width: 36px;
  height: 36px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  top: -18px;
}
.recipe .tobasket {
  right: 65px;
}
.sostav {
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05), 0px 10px 70px #e2e8f6;
  border-radius: 10px;
  padding: 5px 15px;
  margin-bottom: 50px;
}
.sostav table {
  width: 100%;
}
.sostav table td {
  padding: 10px 0;
  vertical-align: middle;
}
.sostav table td:first-child {
  width: 65px;
}
.sostav table:not(:last-child) {
  border-bottom: 1px solid #d7dbe7;
}
.sostav table td:last-child {
  width: 100px;
  text-align: right;
  padding-right: 5px;
}
.sostav__image {
  width: 50px;
  height: 50px;
  line-height: 50px;
  background: #f0f2f7;
  border-radius: 10px;
  text-align: center;
}
.sostav__image img {
  max-width: 80%;
}
#sostav {
  margin: 0 -15px 0 -8px;
}
.sostav__item {
  max-width: 70px;
  text-align: center;
}
.sostav__img {
  height: 70px;
  line-height: 70px;
  background: #d6eca4;
  border-radius: 10px;
}
.sostav__item.active .sostav__img,
.sostav__image.sostav__image--orange {
  background: #fee492;
}
.sostav,
.sostav__item,
.sostav__product {
  font-size: 14px;
}
.sostav__product {
  line-height: 1;
  margin: 8px 0 6px;
}
.white_btn {
  font-size: 12px;
  text-transform: uppercase;
  background: #fff;
  padding: 14px;
  display: table;
  margin-top: 10px;
  text-align: center;
  cursor: pointer;
}
.steps {
  padding-bottom: 25px;
}
.steps__block {
  background: #fff;
  padding: 15px;
  margin-bottom: 15px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05), 0px 10px 70px #e2e8f6;
  border-radius: 10px;
}
.steps__number {
  font-size: 12px;
  text-transform: uppercase;
  margin-bottom: 5px;
}
.plan__ul li {
  position: relative;
  padding: 0 0 20px 25px;
}
.plan__ul li:last-child {
  padding-bottom: 0;
}
.plan__ul li:before {
  display: block;
  content: '';
  width: 18px;
  height: 18px;
  background: url(images/li.svg) no-repeat 0 0;
  position: absolute;
  top: 0;
  left: 0;
}
.scrollul {
  padding-bottom: 40px;
}
.scrollul li {
  display: inline-block;
  width: 70px;
  vertical-align: top;
}
.scrollul li:not(:last-child) {
  margin-right: 12px;
}
.scrollul a,
.scrollul span {
  display: block;
}
.category__title {
  font-size: 44px;
  margin: 20px 0 10px;
}
.categorieslist {
  font-size: 12px;
  text-transform: uppercase;
  padding: 18px 0 0 80px;
  background: url(images/categories.svg) no-repeat 0 50%;
  text-align: center;
  min-height: 70px;
  margin-top: 20px;
}
.categorieslist.categorieslist--two {
  background: url(images/categories2.svg) no-repeat 0 50%;
}
.categorieslist ul {
  display: table;
}
.categorieslist ul li {
  display: table-cell;
  border-right: 1px solid #d7dbe7;
  padding: 0 5px;
}
.categorieslist ul li:last-child {
  border-right-color: transparent;
}
.categorieslist span {
  color: #bdc0c7;
  display: block;
  margin-top: 5px;
}
.cattitle {
  margin: 40px 0 10px;
  text-align: center;
}
.categories__item {
  height: 70px;
  padding-top: 15px;
  background: linear-gradient(92.12deg, #e6e9f2 3.07%, #e5e9f0 100%);
  border-radius: 10px;
  text-align: center;
  max-width: 98px;
}
.categories__item.active {
  color: #fff;
  background: linear-gradient(92.12deg, #f8764c 3.07%, #f55555 100%);
}
.categories__number {
  font-size: 16px;
  width: 26px;
  height: 26px;
  line-height: 26px;
  background: #fff;
  border-radius: 100%;
  margin: 0 auto 5px;
}
.categories__item.active .categories__number {
  color: #f8724e;
}
.tab.product__tab {
  margin-bottom: 0;
}
.product,
.plan,
.train__block,
.formquestion,
.board__block,
.profil__block {
  background: #ffffff;
  border-radius: 10px;
  position: relative;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05), 0px 10px 50px #e2e8f6;
  margin: 20px 0;
}
.plan {
  display: block;
  padding: 10px;
  margin: 0 0 20px;
}
.notenumbers {
  margin-bottom: 30px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05), 0px 10px 50px #e2e8f6;
  padding: 15px;
  border-radius: 10px;
}
.product {
  padding: 10px 10px 10px 120px;
  min-height: 107px;
}
.product.product--basket {
  padding-right: 25px;
}
.product__img {
  width: 89px;
  height: 89px;
  line-height: 89px;
  background: #d6eca4;
  border-radius: 10px;
  position: absolute;
  top: 10px;
  left: 10px;
  text-align: center;
}
.product--basket .product__img {
  background: #f0f2f7;
}
.product__img.product__img--orange {
  background: #fee492;
}
.product__img img {
  max-height: 62px;
}
.product__name {
  font-size: 18px;
  margin: 5px 0 2px;
}
.product__sostav {
  color: #bdc0c7;
  margin-bottom: 19px;
}
.product__sostav:not(.active) {
  text-overflow: ellipsis;
  -moz-text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  height: 20px;
}
.productwrap .tab__block {
  margin: 0 -15px;
  padding: 0 15px 7px;
}
.product__counter {
  min-width: 90px;
  padding: 8px 9px;
  position: absolute;
  bottom: 0;
  right: 0;
}
.productwrap .product__counter {
  min-width: 122px;
  padding-right: 35px;
  padding-left: 29px;
  text-align: center;
}
.productwrap .product__up,
.productwrap .product__down {
  position: absolute;
  top: 8px;
}
.productwrap .product__up {
  right: 9px;
}
.productwrap .product__down {
  left: 0;
}
input[type='text'].product__quantity {
  font-size: 16px;
  width: 31px;
  height: 21px;
  border: none;
  text-align: center;
  background: transparent;
  padding: 0;
}
.product__quantity,
.grey {
  color: #bdc0c7;
}
.product__quantity.chosen {
  color: #000;
}
.product__quantity.green,
.green {
  color: green;
}
.product__quantity.red,
.red {
  color: red;
}
.product__down,
.product__up {
  width: 24px;
  height: 24px;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 100%;
  vertical-align: middle;
}
.product__down {
  background: url(images/down.svg) no-repeat 50% 50%;
  margin-left: 4px;
}
.product__up {
  background: url(images/up.svg) no-repeat 50% 50%;
}
.download,
.train__download {
  font-size: 12px;
  text-transform: uppercase;
  background: linear-gradient(93.75deg, #f98c45 0%, #f55555 100%);
  border-radius: 2px;
  display: table;
  position: relative;
}
.download {
  padding: 17px 46px 18px 16px;
  margin: 0 auto 40px;
}
.train__download {
  padding: 15px 15px 14px 15px;
  margin: 20px 0;
  text-align: center;
}
.download,
.download:hover,
.download:focus,
.train__download,
.train__download:hover,
.train__download:focus {
  color: #fff;
}
.download:before {
  display: block;
  content: '';
  width: 22px;
  height: 22px;
  background: url(images/download.svg) no-repeat 0 0;
  position: absolute;
  top: 14px;
  right: 14px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.not__video {
  font-size: 10px;
  line-height: 1;
  margin-top: 15px;
}
.not__video a,
.not__video span,
.not__video img,
.not__video svg {
  vertical-align: middle;
}
.not__video a,
.not__video svg {
  margin-right: 10px;
}
.not__img {
  position: absolute;
  bottom: 0;
  right: 0;
}
.not__clock {
  line-height: 16px;
  position: relative;
  padding-left: 20px;
  min-height: 16px;
}
.not__clock:before {
  content: '';
  width: 16px;
  height: 16px;
  background: url(images/clock.svg) no-repeat 0 0;
  position: absolute;
  top: 0;
  left: 0;
}
.plan table tr td {
  vertical-align: middle;
}
.plan table tr td:first-child {
  width: 105px;
}
.plan__name,
.train__title {
  font-size: 18px;
}
.plan__link {
  margin-top: 10px;
}
.plan img {
  border-radius: 10px;
  max-width: 89px;
}
.plan .label {
  font-size: 10px;
  text-transform: uppercase;
}
.plan__desc {
  margin-top: 5px;
}
.header {
  background: #fff;
  padding: 15px 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05),
    0px 10px 30px rgba(226, 232, 246, 0.8);
  z-index: 2;
}
.header .container {
  position: relative;
}
.header__menuopen {
  position: absolute;
  top: 5px;
  right: 15px;
}
.header__menuopen span,
.header__menuopened span {
  position: relative;
  background: #000;
  display: block;
  width: 24px;
  height: 2px;
  margin-bottom: 4px;
  border-radius: 2px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}
.header__menuopen span {
  background: #f55555;
}
.active.header__menuopen span:first-child,
.header__menuopened.active span:first-child {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 4px;
}
.active.header__menuopen span:nth-child(2),
.header__menuopened.active span:nth-child(2) {
  opacity: 0;
}
.active.header__menuopen span:last-child,
.header__menuopened.active span:last-child {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: -8px;
}
.header__menuopen span:nth-child(2) {
  width: 16px;
  margin-left: 8px;
}
.header__menuopen span:last-child {
  width: 20px;
  margin-left: 4px;
  margin-bottom: 0;
}
.mobilemenu ul {
  margin: 25px 0 40px;
}
.mobilemenu ul li {
  margin-bottom: 14px;
}
.mobilemenu__overlay {
  display: none;
  position: fixed;
  z-index: 900;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  transition: background-color 0.25s ease-in-out;
  cursor: pointer;
}
.mobilemenu__overlay.active {
  display: block;
  background-color: rgba(0, 0, 0, 0.5);
}
.mobilemenu {
  font-size: 12px;
  text-transform: uppercase;
  text-align: left;
  position: fixed;
  z-index: -999;
  right: 0;
  top: 0;
  width: 90%;
  background: #fff;
  opacity: 0;
  transform: translateX(101%);
  transition: all 0.25s ease 0s;
  will-change: transform;
  height: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 20px 15px 15px;
  overflow: auto;
}
.mobilemenu.active {
  transform: translateX(0);
  opacity: 1;
  z-index: 999;
}
.mobilemenu__img {
  position: absolute;
  left: 20px;
  bottom: 0;
  z-index: -1;
}
.bottommenu {
  font-size: 10px;
  text-transform: uppercase;
  background: #fff;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  box-shadow: 0px -4px 5px rgba(0, 0, 0, 0.02),
    0px -10px 15px rgba(226, 232, 246, 0.4);
}
.bottommenu ul {
  display: flex;
  justify-content: space-between;
}
.bottommenu ul li {
  display: inline-block;
  text-align: center;
}
.bottommenu__img {
  display: block;
  height: 36px;
  margin-bottom: 5px;
}
.bottommenu a {
  padding: 10px 0;
}
.bodynotscrolled {
  overflow: hidden;
  height: 100vh;
}
.btn {
  font-size: 12px;
  text-transform: uppercase;
  padding: 13px 15px;
  background: linear-gradient(93.75deg, #f98c45 0%, #f55555 100%);
  border-radius: 2px;
  border: none;
  cursor: pointer;
}
.header__btn {
  padding: 6px 8px;
  position: absolute;
  top: 50%;
  right: 15px;
  margin-top: -14px;
}
.telegram,
.viber {
  font-size: 12px;
  text-transform: uppercase;
  display: block;
  border-radius: 2px;
  padding: 18px 15px;
  margin: 20px auto 40px;
  max-width: 150px;
  text-align: center;
}
.telegram {
  background: linear-gradient(93.17deg, #32afed 0%, #1b9cdc 100%);
}
.viber {
  background: linear-gradient(93.17deg, #8074d1 0%, #675da9 100%);
}
.telegram .messenger__icon {
  width: 15px;
  height: 12px;
  background: url(images/telegram.svg) no-repeat 0 0;
}
.viber .messenger__icon {
  width: 16px;
  height: 16px;
  background: url(images/viber.svg) no-repeat 0 0;
}
.telegram svg,
.messenger__icon {
  margin-right: 5px;
}
.chat {
  font-size: 14px;
  margin-bottom: 40px;
}
.row {
  margin-left: -15px;
  margin-right: -15px;
}
.row:after {
  display: block;
  content: '';
  clear: both;
}
.col-6,
.train__col,
.board__col,
.plan__col {
  float: left;
  width: 50%;
  padding: 0 15px;
  box-sizing: border-box;
}
.chat__block {
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05), 0px 10px 70px #e2e8f6;
  border-radius: 18px;
  padding: 12px 10px 55px;
  text-align: center;
  box-sizing: border-box;
  position: relative;
}
.chat__block .btn {
  position: absolute;
  bottom: 12px;
  left: 50%;
  transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -o-transform: translateX(-50%);
}
.chat__title {
  font-size: 18px;
  margin-bottom: 12px;
}
.chat__block p {
  margin-bottom: 11px;
}
.trainslider {
  margin: -15px -10px 0 -8px;
}
.excercisePlay {
  position: absolute;
  bottom: 6px;
  left: 6px;
}
.trainPreview {
  max-width: 100%;
  object-fit: contain;
  border-radius: 10px;
}
.trainslider .item {
  box-sizing: border-box;
  padding: 5px 8px 0;
}
.not__rating {
  font-size: 10px;
  margin-bottom: 15px;
}
.trainslider .not {
  padding: 10px 155px 15px 15px;
}
.instagram {
  line-height: 14px;
  background: url(images/instagram.svg) no-repeat 0 0;
  padding-left: 19px;
  min-height: 14px;
}
.not__video a.instagram {
  margin-right: 0;
}
.recipe__text .not__clock {
  margin-right: 13px;
}
.recipe__text.trains__text {
  margin-bottom: 40px;
}
.day {
  font-size: 12px;
  text-transform: uppercase;
  position: relative;
}
.day:before {
  display: block;
  content: '';
  border-top: 1px dashed #d8deeb;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
}
.day span {
  background: #f0f2f7;
  border-radius: 2px;
  padding: 2px 6px;
  position: relative;
}
.train__block {
  min-height: 112px;
  padding: 10px 10px 10px 115px;
  display: block;
}
.not__rating,
.not__video {
  display: block;
}
.not:hover,
.not:focus {
  color: #fff;
}
.train__img {
  width: 89px;
  height: 94px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 10px;
  overflow: hidden;
  position: absolute;
  top: 10px;
  left: 10px;
}
.train__img svg {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -20px 0 0 -20px;
}
.train__block .not__rating,
.train__title {
  margin-bottom: 8px;
}
.train__title {
  min-height: 44px;
  display: block;
}
.train__block .label {
  font-size: 14px;
  margin-right: 10px;
}
.train__block .not__clock {
  margin-right: 10px;
}
.not__clock,
.instagram {
  font-size: 10px;
}
.train__block--red {
  background: #ffdad1;
}
.train__block--green {
  background: #b3ece8;
}
.train__download img {
  margin-left: 8px;
}
.formquestion {
  padding: 16px;
}
.blocktitle {
  font-size: 18px;
  margin-bottom: 10px;
}
textarea {
  font-size: 16px;
  color: #989da7;
  font-family: 'FuturaPT-Book', Arial, sans-serif;
  resize: none;
  padding-top: 15px;
}
.formquestion input[type='text'],
input[type='number'],
.formquestion textarea,
.profil input[type='text'],
select {
  color: #000;
}
.formquestion input[type='text'],
input[type='number'],
.formquestion textarea,
.profil input[type='text'],
select,
input[type='password'],
.MuiInput-formControl {
  background: rgba(255, 255, 255, 0.4);
  height: 50px;
  padding-left: 20px;
  box-shadow: inset 0px 0px 8px rgba(0, 0, 0, 0.13), inset 0px 0px 10px #e2e8f6;
}
.formquestion .inputNumberNotValid {
  border: 2px solid lightcoral;
}
.formquestion .inputNumberDefault {
  border: none;
}
select {
  font-size: 16px;
  color: #989da7;
  font-family: 'FuturaPT-Book', Arial, sans-serif;
  background: url(images/select.svg) no-repeat 98% 50% rgba(255, 255, 255, 0.4);
  border: none;
}
select[multiple] {
  height: auto;
  background: #fff;
  padding: 10px 20px;
}
select,
input[type='password'],
.MuiInput-formControl {
  margin-bottom: 20px;
}
.profil select,
.zamerform .MuiInputBase-root {
  margin-bottom: 0;
}
.profil .MuiFormControl-root.MuiTextField-root,
.zamerform .MuiFormControl-root.MuiTextField-root {
  width: 100%;
}
.profil .MuiInput-underline:before,
.profil .MuiInput-underline:after,
.zamerform .MuiInput-underline:before,
.zamerform .MuiInput-underline:after {
  display: none;
}
.formquestion p {
  font-size: 14px;
  color: #bdc0c7;
  margin-bottom: 10px;
}
.formquestion p span {
  text-decoration: underline;
  display: inline;
}
.formlabel {
  margin-top: 9px;
  position: relative;
}
.formlabel input[type='radio'] {
  position: absolute;
  top: 0;
  left: 0;
}
.formlabel input[type='radio'] + label {
  display: block;
  padding-left: 30px;
}
input[type='checkbox'],
input[type='radio'] {
  width: 20px;
  height: 20px;
  box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.15),
    inset 0px 0px 10px rgba(0, 0, 0, 0.2), inset 0px 0px 10px #e2e8f6;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 5px;
  margin: 0 10px 0 0;
  border: none;
}
input[type='checkbox']:checked,
input[type='radio']:checked {
  background: url(images/checked.svg) no-repeat 50% 50%;
  box-shadow: none;
}
button[type='submit'] {
  font-size: 12px;
  text-transform: uppercase;
  background: linear-gradient(93.75deg, #f98c45 0%, #f55555 100%);
  border-radius: 2px;
  padding: 14px 16px;
  display: table;
  margin: 40px auto 0;
  cursor: pointer;
}
button[type='submit'] img,
.btn img {
  margin-left: 5px;
}
.avatar {
  border-radius: 100%;
}
.file_col input[type='file'] {
  visibility: hidden;
  height: 0;
  overflow: hidden;
  display: block;
}
.profil__avatar {
  text-align: center;
  margin-bottom: 25px;
}
.input_file {
  display: table;
  margin: 25px auto 5px;
  padding: 17px 15px;
  cursor: pointer;
}
h1 span {
  display: inline;
}
.board__block {
  padding: 15px 10px 15px 100px;
  margin: 0 0 20px 0;
}
.board__img {
  width: 72px;
  height: 72px;
  position: absolute;
  left: 10px;
  top: 10px;
}
.board__img img {
  border-radius: 100%;
}
.board__place {
  font-size: 10px;
  text-transform: uppercase;
  text-align: center;
  background: #e2e8f6;
  padding: 2px 0;
  border-radius: 2px;
  position: absolute;
  left: 11px;
  right: 11px;
  bottom: 0;
}
.board__block table {
  text-align: center;
  width: 100%;
}
.board__block table tr td {
  width: 33.33%;
  border-right: 1px solid #d7dbe7;
}
.board__block table tr td:last-child {
  border-right-color: transparent;
}
.board__block table div,
.profil__params p {
  font-size: 10px;
  text-transform: uppercase;
  color: #bdc0c7;
}
.board__block table p {
  font-size: 14px;
}
.board .board__col:first-child .board__place {
  color: #fff;
  background: #f55555;
}
.board--steps .board__block table tr td:first-child {
  width: 68%;
}
.board--steps .board__block table tr td:last-child {
  width: 32%;
}
.info {
  font-size: 14px;
  color: #bdc0c7;
  margin-bottom: 40px;
}
.info:before {
  display: inline-block;
  content: '';
  width: 15px;
  height: 15px;
  background: url(images/info.svg) no-repeat 0 0;
  margin-right: 5px;
  position: relative;
  top: 1px;
}
.board__chat {
  font-size: 14px;
  max-width: 150px;
  margin: 0 auto;
}
.board__chat p {
  min-height: 45px;
}
.text-center {
  text-align: center;
}
.planmenu {
  font-size: 12px;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 40px;
}
.planmenu ul {
  box-shadow: inset 0px 0px 6px rgba(0, 0, 0, 0.1);
  border-radius: 24px;
}
.planmenu ul,
.planmenu li {
  display: inline-block;
}
.planmenu li a {
  color: #bdc0c7;
  padding: 14px 15px;
  position: relative;
}
.planmenu li.active a {
  color: #fff;
  background: linear-gradient(93.75deg, #f98c45 0%, #f55555 100%);
  border-radius: 21px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
}
.planmenu__basket {
  color: #fff;
  background: #f55555;
  width: 20px;
  height: 20px;
  line-height: 20px;
  border-radius: 100%;
  position: absolute;
  top: -7px;
  right: -5px;
  box-shadow: 0 0 5px #fff;
}
.userform {
  max-width: 767px;
  margin: 0 auto;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05),
    0px 10px 70px rgba(226, 232, 246, 0.8);
  border-radius: 40px 40px 0 0;
  padding: 40px;
  position: relative;
  width: 100%;
}
.userform form {
  margin: 0;
}
input[type='submit'].btn {
  width: 100%;
  height: 54px;
  background: linear-gradient(93.75deg, #f98c45 0%, #f55555 100%);
  position: relative;
  top: 0;
  right: 0;
}
.userform .title {
  font-size: 32px;
  margin-top: 0;
}
.userform__link {
  font-size: 14px;
  margin-top: 20px;
}
.userform__link a,
.tab__item.active,
.MuiTab-textColorInherit.Mui-selected {
  color: #f55555;
}

.userform__link a {
  cursor: pointer;
}

body.userform__body {
  padding: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  overflow: auto;
}
.userform__wrapper {
  display: flex;
  min-height: 100vh;
  align-items: flex-end;
  background: url(images/photo2.png) no-repeat 50% 55px;
  background-size: contain;
  padding-top: 55px;
}
.readmore__block {
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05), 0px 10px 70px #e2e8f6;
  border-radius: 16px;
  margin-bottom: 30px;
}
.readmore__text {
  padding: 13px 20px 20px;
}
.readmore__title {
  font-size: 20px;
  min-height: 56px;
}
.readmore__img {
  height: 190px;
  border-radius: 16px 16px 0 0;
  display: block;
}
.flexrow {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.col-12,
.col-sm-12,
.col-md-12,
.col-lg-6,
.col-xl-6 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.readmore__info .articles__date,
.readmore__info .articles__counter {
  margin-right: 35px;
}
.readmore__info .articles__comment {
  margin-right: 0;
}
.readmore__info,
.articles__comment {
  color: #919297;
}
.notenumbers {
  margin-bottom: 30px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05), 0px 10px 50px #e2e8f6;
  padding: 15px;
  border-radius: 10px;
}
.plan__container .tab__block {
  margin-left: -15px;
  margin-right: -15px;
  padding-left: 15px;
  padding-right: 15px;
}
input[type='text'].search__input {
  background: rgba(255, 255, 255, 0.4);
  box-shadow: inset 0px 0px 8px rgba(0, 0, 0, 0.13), inset 0px 0px 10px #e2e8f6;
}
.row.time__row {
  margin: 0 -8px 40px;
}
.time__row .col-6 {
  padding: 0 8px;
}
.title__food {
  margin-top: 30px;
}
.sort {
  color: #989da7;
  margin-top: 10px;
}
.sort select {
  color: #f55555;
  background: url(images/select2.svg) no-repeat 98% 50% #fff;
  height: 20px;
  width: auto;
  box-shadow: none;
  padding: 0 20px 0 0;
}
.sort span {
  margin-right: 4px;
}
.search__form button[type='submit'] {
  margin: 0;
  background: transparent;
  width: 34px;
  height: 34px;
  line-height: 31px;
  border-radius: 6px;
  padding: 0;
  position: absolute;
  top: 6px;
  right: 6px;
}
.search__form button[type='submit'] svg {
  vertical-align: middle;
}
.search__form button[type='submit']:focus {
  background: linear-gradient(92.12deg, #f8764c 3.07%, #f55555 100%);
}
.search__form button[type='submit']:focus svg path {
  fill: #fff;
}
.recipe__item.recipe__item--found {
  padding: 0 0 20px;
}
.recipe__item--found .recipe__image {
  height: 266px;
}
.notfound {
  font-size: 14px;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05), 0px 10px 70px #e2e8f6;
  border-radius: 18px;
  padding: 15px 15px 0;
  text-align: center;
  margin-bottom: 40px;
}
.notfound__title {
  font-size: 18px;
  margin-bottom: 10px;
}

.notfound__title--daily {
  padding-bottom: 5px;
}

.notfound.notfound--search {
  padding-bottom: 15px;
}
.notfound--search img {
  margin-bottom: 13px;
}
.slider__title {
  height: 20px;
  text-overflow: ellipsis;
  -moz-text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.menuSlider {
  overflow-y: hidden !important;
}
.title__find {
  margin-bottom: 10px;
}
.measure_err {
  color: #f55555;
  padding-top: 5px;
}
.measure__row {
  display: flex;
  text-align: center;
  align-items: center;
  margin-bottom: 10px;
}
.measure__date {
  font-size: 12px;
  line-height: 1;
  text-transform: uppercase;
  color: #bdc0c7;
  width: 13%;
}
.measure__date div {
  font-size: 16px;
}
.measure__edit span {
  width: 5px;
  height: 5px;
  background: #bdc0c7;
  border-radius: 100%;
  margin-right: 3px;
}
.measure__row.edited .measure__edit span {
  background: #f55555;
}
.measure__numbers {
  font-size: 18px;
  width: 87%;
  display: flex;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.03),
    0px 2px 15px rgba(226, 232, 246, 0.4);
  border-radius: 10px;
  padding: 14px 0;
  border: 1px solid transparent;
  position: relative;
}
.measure__row--top .measure__numbers {
  box-shadow: none;
  padding: 0;
}
.measure__row--top .measure__weight,
.measure__row--top .measure__belly {
  border-color: transparent;
}
.measure__weight {
  width: 29%;
}
.measure__belly {
  width: 27%;
}
.measure__buttocks {
  width: 28%;
}
.measure__editcol {
  width: 16%;
}
.measure__weight,
.measure__belly {
  border-right: 1px solid #d7dbe7;
}
.measure__row.edited .measure__weight,
.measure__row.edited .measure__belly {
  border-color: #f55555;
}
.measure__edit {
  position: relative;
  top: -4px;
  cursor: pointer;
}
.measure__row.edited .measure__date {
  color: #f55555;
}
.measure__row.edited .measure__numbers {
  background: #ffeaea;
  border-color: #f55555;
}
.measure__editpopup {
  background: #ffffff;
  border: 1px solid #d7dbe7;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05), 0px 10px 70px #e2e8f6;
  border-radius: 6px;
  padding: 10px 30px 10px 10px;
  text-align: left;
  position: absolute;
  top: 77%;
  right: 0;
  z-index: 1;
}
.measure__row.edited .measure__editpopup {
  opacity: 1;
  z-index: 1;
}
.measure__editpopup img {
  margin-right: 6px;
}
.measure__editpopup div:not(:last-child) {
  margin-bottom: 20px;
}
.measure__period {
  color: #989da7;
  background: #ffffff;
  box-shadow: inset 0px 0px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 8px 15px 8px 20px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.measure__period .MuiInput-formControl {
  margin: 0;
  padding: 0;
  box-shadow: none;
  background: transparent;
  height: auto;
}
.measure__period .MuiInput-underline:before,
.measure__period .MuiInput-underline:after {
  display: none;
}
.measure__period select {
  color: #000000;
  height: 32px;
  max-width: 105px;
  box-shadow: none;
  padding: 0 2px 0 0;
  margin: 0;
}
.product__counttext {
  color: #bdc0c7;
  position: absolute;
  bottom: 8px;
  left: 120px;
}
.product--basket input[type='text'].product__quantity {
  color: #000;
  width: 20px;
}
.sostav--basket .sostav__image {
  background: #f0f2f7;
}
.sostav--basket input[type='checkbox'],
.sostav--removing input[type='checkbox'] {
  margin: 0 0 0 15px;
  vertical-align: middle;
}
.sostav.sostav--basket {
  margin: 60px 0 30px;
}
.sostav.sostav--basket table td:last-child,
.sostav.sostav--removing table td:last-child {
  padding-right: 0;
}
.sostav.sostav--removing {
  color: #989da7;
  background: transparent;
  box-shadow: none;
  margin-bottom: 30px;
}
.sostav.sostav--removing table:not(:last-child) {
  border: none;
}
.sostav__removing {
  color: #989da7;
}
.sostav--removing table td:nth-child(2),
.sostav__removing td:nth-child(2) {
  text-decoration: line-through;
}
.sostav--removing img,
.sostav__removing img {
  filter: grayscale(1);
}
.sostav.sostav--removing table td {
  padding: 5px 0;
}
.formquestion .MuiFormControlLabel-root {
  margin: 0;
}
.formquestion .MuiFormControlLabel-root:not(:last-child) {
  margin-bottom: 7px;
}
.formquestion .MuiRadio-root {
  padding: 0;
}
.formquestion .MuiIconButton-label input[type='radio'] {
  opacity: 1;
  position: relative;
}
.formquestion .MuiSvgIcon-root {
  display: none;
}
.formquestion .MuiTypography-body1,
.popup--style .MuiTypography-body1 {
  font-size: 16px;
}
.MuiDialog-paper {
  min-width: 150px;
}
.popup--style .MuiDialog-paper {
  color: #1d2126;
  max-width: 330px;
  text-align: center;
  border-radius: 10px;
  padding-top: 60px;
  background: url(images/popup.svg) no-repeat 50% 24px #fff;
}
.popup--style.popup--blue .MuiDialog-paper {
  background: url(images/popup--blue.svg) no-repeat 50% 20px #fff;
}
.popup--style .MuiTypography-body1 {
  line-height: 1.25;
}
.popup--style .MuiTypography-h6 {
  font-size: 18px;
  line-height: 1.11;
}
.popup--style .MuiDialogTitle-root {
  padding: 16px 10px 5px;
}
.popup--style .MuiDialogContent-dividers {
  border: none;
  padding: 0 10px;
}
.popup--style .alert-close {
  right: 0;
  top: 0;
}
.popup--style .MuiDialogContent-dividers.popup__bottom {
  color: #989da7;
  text-transform: uppercase;
  border-top: 1px solid #ebecee;
  margin-top: 15px;
  padding: 14px 10px;
  cursor: pointer;
}
.popup--style .popup__bottom .MuiTypography-body1 {
  font-size: 12px;
}
.popup__bottom p {
  margin: 0;
}
.popup--social .telegram,
.popup--social .viber {
  float: left;
  width: 50%;
  margin: 0;
  max-height: 51px;
  max-width: 100%;
  border-radius: 0;
}
.popup--social.popup--style .MuiDialogContent-dividers.popup__bottom {
  padding: 0;
}
.popup--product .popup__bottom {
  display: none;
}
.product__ul {
  min-width: 220px;
}
.product__ul li {
  display: inline-block;
  width: 49%;
  vertical-align: top;
  padding-left: 15px;
  position: relative;
  margin-bottom: 8px;
}
.product__ul li:before {
  display: block;
  content: '';
  width: 7px;
  height: 7px;
  background: linear-gradient(93.75deg, #f98c45 0%, #f55555 100%);
  border-radius: 100%;
  position: absolute;
  top: 7px;
  left: 0;
}
.product__ul li::first-letter {
  text-transform: uppercase;
}
.trains__watch .title {
  font-size: 18px;
  margin-bottom: 10px;
}
.trains__watch img {
  margin-top: -50px;
}
.trains__watch + .title {
  margin-top: -40px;
}
.trains__text .not__rating {
  margin-bottom: 10px;
}
.label__time {
  font-size: 14px;
  margin-right: 10px;
}
.product__delete {
  position: absolute;
  top: 10px;
  right: 10px;
}
.btn,
.btn:hover,
.btn:focus,
.telegram,
.telegram:hover,
.telegram:focus,
button[type='submit'],
button[type='submit']:hover,
button[type='submit']:focus,
.viber,
.viber:hover,
.viber:focus {
  color: #fff;
}
.telegram svg,
.not__rating img,
.not__rating span,
.not__clock,
.instagram,
.train__block .label,
.formlabel input,
.formlabel label,
.profil__progress img,
.readmore__info span,
.readmore__info svg,
.telegram span,
.viber span,
.measure__editpopup img,
.measure__editpopup span,
.btn span {
  vertical-align: middle;
}
a,
.wish,
.tab__img,
.tab__block,
input,
.openblock span,
.hiddenblock,
.MuiAvatar-root.MuiAvatar-circle,
.measure__date,
.measure__numbers,
.measure__editpopup,
.measure__edit span,
.measure__weight,
.measure__belly {
  transition: all 0.25s ease 0s;
  -webkit-transition: all 0.25s ease 0s;
  -moz-transition: all 0.25s ease 0s;
  -o-transition: all 0.25s ease 0s;
}
.regAlert .regLink {
  font-size: large;
}
.regLink {
  color: red;
}
.noBox {
  box-shadow: none;
  padding: 0;
}
.invalidInput {
  border: 2px solid lightcoral;
}
.currentWeight {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.currentWeight .btn {
  margin-top: 12px;
}
.currentWeight .btn img {
  width: 14px;
  height: 14px;
  margin-left: 10px;
}
@media (min-width: 1200px) {
  .col-xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}
@media (min-width: 992px) {
  .col-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}
@media (min-width: 768px) {
  .measure__period .MuiInput-formControl {
    max-width: 135px;
  }
  .profil__container {
    display: table;
    margin: auto;
  }
}
@media (max-height: 620px) {
  .mobilemenu__img {
    max-width: 150px;
  }
}
@media (max-height: 530px) {
  .mobilemenu__img {
    display: none;
  }
}
@media (max-width: 991px) {
  .train__col {
    width: 100%;
  }
  .train__row .train__col:not(:first-child) .train__block {
    margin-top: 0;
  }
}
@media (max-width: 767px) {
  .board__col,
  .plan__col {
    width: 100%;
  }
  select {
    background-position: 92% 50%;
  }
  .readmore__info .articles__date,
  .readmore__info .articles__counter {
    margin-right: 10px;
  }
  .readmore__block {
    margin-bottom: 15px;
  }
  .readmore__title {
    font-size: 18px;
    line-height: 26px;
    min-height: 0;
  }
  .userform {
    padding-bottom: 90px;
  }
  .MuiDialog-container.MuiDialog-scrollPaper .MuiTextField-root {
    width: 100% !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .MuiDialog-container.MuiDialog-scrollPaper
    .MuiAutocomplete-root.MuiAutocomplete-hasClearIcon {
    width: 100%;
  }
  .MuiDialog-container.MuiDialog-scrollPaper
    div[class^='makeStyles-root']
    + div,
  .MuiDialog-container.MuiDialog-scrollPaper .jss8 + div {
    width: 100% !important;
  }
  .MuiDialog-container.MuiDialog-scrollPaper
    .MuiFormControl-root.MuiTextField-root
    + div {
    word-break: break-word;
  }
  .MuiDialog-root {
    overflow: auto;
  }
  .MuiDialog-root .MuiDialog-container.MuiDialog-scrollPaper,
  .MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiDialog-paperFullScreen,
  .jss8 {
    display: block;
  }
  .slider__title {
    height: 40px;
    -webkit-line-clamp: 2;
  }
  input[type='date']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    display: none;
  }
  input[type='date']::-webkit-calendar-picker-indicator {
    -webkit-appearance: none;
    display: none;
  }
  .measure__period input[type='date'] {
    background: url(images/select.svg) no-repeat 94% 50%;
  }
}
@media (max-width: 400px) {
  .not,
  .trainslider .not {
    padding-right: 75px;
  }
  .not__img,
  .trainslider .not__img {
    max-width: 75px;
  }
  .categorieslist {
    padding-left: 72px;
    font-size: 10px;
  }
  .categorieslist ul {
    float: right;
  }
  .categorieslist ul li {
    padding: 0 2px;
  }
}
@media (max-width: 355px) {
  .planmenu {
    font-size: 11px;
  }
  .planmenu li a {
    padding: 12px 8px;
  }
}
@media (max-width: 340px) {
  .MuiTab-labelIcon.MuiTab-root:not(:last-child) {
    margin-right: 2px;
  }
}

.plan__normtitle {
  font-size: 12px;
  font-family: 'FuturaPT-Demi', Arial, sans-serif;
  margin-bottom: 5px;
}
.plan__normtitle span {
  border-radius: 2px;
  padding: 0 4px;
}
.plan__normtitle span:first-child {
  background: #f0f2f7;
}
.plan__normtitle span:last-child {
  color: #fff;
  background: #f55555;
}
input[type='text'].product__quantity,
input[type='text'].product__quantity + span {
  vertical-align: middle;
  box-shadow: none;
  margin-bottom: 0;
}
.infoblock {
  font-size: 14px;
  color: #f55555;
  background: url(./images/info.svg) no-repeat 8px 10px #fef3f3;
  border: 1px solid #f99494;
  border-radius: 10px;
  padding: 10px 15px 10px 33px;
}
.plan__norm + .infoblock {
  margin: -30px 0 40px;
}

.train__downloadlink {
  font-size: 14px;
  display: block;
  text-align: center;
  padding: 15px 12px;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05), 0px 10px 70px #e2e8f6;
  border-radius: 18px;
}
.train__downloadlinkimg {
  display: block;
  width: 30px;
  height: 30px;
  line-height: 27px;
  background: #f0f2f7;
  border-radius: 4px;
  margin: 0 auto 10px;
}
.row--sm {
  margin-left: -7px;
  margin-right: -7px;
  position: relative;
}
.row--sm:after {
  display: block;
  content: '';
  clear: both;
}
.col-6--sm {
  padding: 0 7px;
  box-sizing: border-box;
  float: left;
  width: 50%;
}

.profil__block {
  max-width: 330px;
  padding: 25px 15px;
  margin-bottom: 40px;
}
.profil__block--top {
  text-align: center;
  padding: 40px;
  margin: 105px 0 40px;
}
.profil__block--top .btn {
  display: block;
}
.profil__img {
  margin: -105px 0 10px;
}
.profil__progressline,
.profil__paramsline {
  display: flex;
  justify-content: space-between;
}
.profil__progressline {
  margin-bottom: 20px;
}
.profil__paramsline {
  margin-bottom: 10px;
}
.profil__progress {
  width: 54px;
  height: 54px;
  line-height: 51px;
  position: relative;
}
.profil__circle {
  height: auto;
  max-width: 54px;
  transform: rotate(-90deg);
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.profil__circle circle {
  fill: rgba(0, 0, 0, 0);
  stroke-dashoffset: 219.91148575129;
  stroke-width: 5;
  stroke: #f55555;
}
.profil__circle circle.greyline {
  stroke: #d7dbe7;
}
.profil__circle circle.complete {
  stroke-dasharray: 219.91148575129;
}
.profil__params div {
  font-size: 18px;
  margin-top: 5px;
}
.profil__line {
  height: 10px;
  background: #f0f2f7;
  border-radius: 5px;
  position: relative;
  margin-bottom: 25px;
}
.profil__line div {
  height: 100%;
  background: linear-gradient(92.12deg, #f8764c 3.07%, #f55555 100%);
  border-radius: 5px;
  position: absolute;
  top: 0;
  left: 0;
}
.profil__btn {
  max-width: 330px;
  display: flex;
  flex-direction: column;
}
.profil__btn .btn {
  margin-bottom: 15px;
}
.openblock {
  text-align: center;
}
.openblock span {
  padding-right: 25px;
  background: url(./images/open.svg) no-repeat right center;
  cursor: pointer;
}
.openblock.opened span {
  background: url(./images/opened.svg) no-repeat right center;
}
.hiddenblock:not(.opened) {
  height: 0;
  overflow: hidden;
}
.formquestion select + .infoblock {
  margin-top: 15px;
}
.profil__save {
  min-width: 200px;
}
.subscription {
  text-transform: uppercase;
  font-size: 12px;
  line-height: 14px;
  padding: 12px 15px;
  border: 3px solid #f8754d;
  border-radius: 2px;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.subscription__date {
  color: #f8754d;
}
.bird_wrapper {
  width: 18px;
  height: 18px;
  border-radius: 100%;
  background: linear-gradient(93.75deg, #f98c45 0%, #f55555 100%);
  position: relative;
  margin-right: 2px;
}
.bird_checkMark {
  position: absolute;
  left: 4px;
  top: 5px;
}
@media (max-width: 767px) {
  .profil__block,
  .profil__btn {
    max-width: 100%;
  }
}

.notfound--inbasket {
  position: relative;
}
.notfound--inbasket:before {
  content: '';
  height: 46px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.9) 100%
  );
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 0px 0px 18px 18px;
}
.sostav__wrap {
  position: relative;
}
.sostav__wrap .day {
  position: absolute;
  top: -28px;
  left: 0;
  right: 0;
}
.sostav.sostav--basket:empty,
.sostav.sostav--basket:empty + .day {
  display: none;
}

.search__ingredient {
  color: #f55555;
  background: #ffe6e2;
  border-radius: 20px;
  padding: 8px 27px 7px 11px;
  position: relative;
  margin: 10px 10px 0 0;
}
.search__ingredient svg {
  position: absolute;
  top: 14px;
  right: 11px;
  cursor: pointer;
}
.search__ingredient:nth-child(3n + 3) svg rect {
  fill: #ff9f18;
}
.search__ingredient:nth-child(3n + 2) svg rect {
  fill: #89a48c;
}
.menusearch .title {
  margin-bottom: 10px;
}
.menusearch button[type='submit'] {
  line-height: 1;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 5px;
  right: 5px;
  border-radius: 6px;
}

input[type='text'],
input[type='email'],
input[type='password'],
input[type='confirmpassword'] {
  background: rgba(255, 255, 255, 0.4);
  box-shadow: inset 0px 0px 8px rgba(0, 0, 0, 0.13), inset 0px 0px 10px #e2e8f6;
  margin-bottom: 20px;
  height: 50px;
  padding-left: 20px;
}
