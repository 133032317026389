.container {
    margin-bottom: 16px;
    padding: 15px;
    background: #FFFFFF;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05), 0 10px 70px #E2E8F6;

    .title {
        font-family: 'FuturaPT-Demi', Arial, sans-serif;
        font-size: 1.2em;
        line-height: 1.2em;
    }

    .blockInner {
        margin: 16px 0;
        font-size: 1.2em;
        animation: fadeEffect 1s forwards;

        @keyframes fadeEffect {
            from {
                opacity: 0;
            }
            to {
                opacity: 1;
            }
        }

        a {
            text-decoration: underline;
            color: #000000;
        }

    }

    .video {
        cursor: pointer;
        overflow: hidden;
        padding-bottom: 56.25%;
        position: relative;
        height: 0;

        img {
            border-radius: 10px;
        }
    }
}