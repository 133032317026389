.productwrap {
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05), 0 10px 50px #e2e8f6;
  border-radius: 16px;

  .product {
    display: flex;
    align-items: center;
    background: #ffffff;
    position: relative;
    padding: 10px 10px 10px 120px;
    min-height: 90px;
    &:first-child {
      border-radius: 11px 11px 0 0;
    }
    &:last-child {
      border-radius: 0 0 11px 11px;
    }

    &:not(:last-child) {
      border-bottom: 2px solid #e5e5e5;
    }

    .product__img {
      width: 89px;
      height: 63px;
      line-height: 89px;
      background: #d6eca4;
      border-radius: 10px;
      position: absolute;
      top: 10px;
      left: 10px;
      text-align: center;

      img {
        max-height: 43px;
        vertical-align: sub;
      }
    }

    .product__img_orange {
      background: #fee492;
    }

    .product__description {
      .product__name {
        font-family: 'FuturaPT-Demi', Arial, sans-serif;
      }

      .product__link {
        color: #f55555;
        cursor: pointer;
        &:hover {
          color: #1d2126;
        }
      }
    }

    .product__counter {
      font-family: 'FuturaPT-Medium', Arial, sans-serif;
      min-width: 110px;
      position: absolute;
      right: 0;
      padding-right: 12px;
      padding-left: 29px;
      text-align: center;
      display: flex;

      .product__up,
      .product__down {
        width: 24px;
        height: 24px;
        cursor: pointer;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 100%;
        vertical-align: middle;
      }

      .product__down {
        background: url(../../images/down.svg) no-repeat 50% 50%;
      }

      .product__up {
        background: url(../../images/up.svg) no-repeat 50% 50%;
      }

      .product__unit {
        display: flex;
        justify-content: center;
        width: 48px;

        input[type='text'].product__quantity {
          font-family: 'FuturaPT-Medium', Arial, sans-serif;
          font-size: 16px;
          height: 21px;
          border: none;
          text-align: center;
          background: transparent;
          padding: 0;
          box-shadow: none;
        }

        .unit_of_measurement {
          margin-right: 4px;
        }

        .product__quantity,
        .grey {
          color: #bdc0c7;
        }

        .chosen {
          color: #000;
        }

        .green {
          color: green;
        }

        .red {
          color: red;
        }
      }
    }
  }
}

.title {
  font-size: 20px;
  line-height: 1.1;
  margin: 20px 0;
  font-family: 'FuturaPT-Bold', Arial, sans-serif;
}

.note {
  padding: 15px 120px 15px 15px;
  margin: 40px 0;
  border-radius: 18px;
}
