.linkChatBlock {
    margin-top: 14px;
}

.title {
    display: flex;
    flex-direction: column;
    color: #F55555;
    margin: 16px 0 20px 0;

    span {
        color: #000;
    }

    img {
        margin-left: 8px;
    }
}


.buttonsBlock {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .subBlock {
        button {
            &:hover {
                cursor: pointer;
            }

            &:first-child {
                margin: 0 14px 14px 0;
            }
        }
    }
}