.button {
    display: flex;
    align-items: center;
    background: linear-gradient(93.75deg, #F98C45 0%, #F55555 100%);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    border-radius: 7px;
    font-style: normal;
    font-weight: 500;
    font-size: 1.2em;
    line-height: 1.2em;
    text-transform: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    border: none;
    font-family: 'FuturaPT-Demi', Arial, sans-serif;
    color: #ffffff;
}