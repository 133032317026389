.chatblock {
    font-size: 14px;
    line-height: 1.29;
    padding: 15px 15px 15px 130px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05), 0 10px 70px #E2E8F6;
    border-radius: 10px;
    position: relative;
    margin-bottom: 20px;
    min-height: 130px;
}

.chatblock img {
    position: absolute;
    top: 15px;
    left: 15px;
}

.chatblock .btn {
    font-size: 10px;
    padding: 9px 10px;
}

.chatblock p {
    margin-bottom: 9px;
}

.chatblock .chat__title {
    margin-bottom: 0;
}

.chatblock + .title {
    padding-top: 20px;
}

.white_btn.white_btn--telegram {
    color: #1B9CDC;
    display: block;
    border-radius: 2px;
    max-width: 112px;
    padding: 11px;
}

.white_btn--telegram svg {
    vertical-align: middle;
    margin-right: 5px;
}

.title + .note {
    margin-top: 20px;
}

.plan__block {
    line-height: 1.12;
    padding: 15px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05), 0 10px 70px #E2E8F6;
    border-radius: 10px;
    margin-bottom: 40px;
}

.plan__blockinner {
    padding-right: 100px;
    position: relative;
}

.plan__blocknote {
    font-size: 14px;
}

.plan__block img {
    position: absolute;
    top: -20px;
    right: -30px;
}

.plan__block .title {
    margin-bottom: 10px;
}

.plan__block p {
    margin-bottom: 15px;
}

.btn.btn--bot {
    background: linear-gradient(93.17deg, #32AFED 0%, #1B9CDC 100%);
    display: block;
    text-align: center;
    max-width: 480px;
}

.btn--bot svg {
    vertical-align: middle;
    margin-left: 5px;
}